body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

.dark-mode {
  background: #000;
}

.dark-mode #root {
  color: #fff;
}

button.big {
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  line-height: 1em;
}

button.normal {
  padding: 2px;
  cursor: pointer;
  margin: 2px;
  line-height: 1em;
}

.dark-mode .icon-button {
  color: #fff;
}

.header-message {
  background-color: rgba(255, 255, 0, 0.7);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  border-bottom: 1px #000 solid;
  cursor: pointer;
}

.emoji-picker-react button {
  margin: 0;
}

.players button {
  padding: 2.5px;
  margin: 0 5px;
}

.player {
  border: 1px #000 solid;
  padding: 10px;
  line-height: 1em;
}

.dark-mode .player {
  border-color: #fff;
}

.errors {
  position: fixed;
  z-index: 100;
  background: #f00;
  color: #fff;
  padding: 0 10px;
}

.trick {
  padding: 10px;
}

.card {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  user-select: none;
  font-family: "Apple Symbols", "Segoe UI Symbol", "Symbola", "DejaVu Sans",
    system;
  position: relative;
  margin-top: 20px;
}

.card.highlighted {
  border-bottom: 1px solid;
  margin-bottom: -1px;
}

.card > svg {
  background: #fff;
  margin-left: 1px;
}

.dark-mode .card > svg {
  background: #000;
}

.card:first-child > svg {
  padding: 0;
}

.unknown,
.card.unknown {
  cursor: default;
  color: #aaa;
}

.card .card-label {
  display: none;
  position: absolute;
  font-size: initial;
  left: 0.35em;
  z-index: 1;
  background: #fff;
  width: 1.5em;
}

.dark-mode .card .card-label {
  background: #000;
}

.more .card .card-label {
}

.hand .unselected-cards.unclickable .card {
  cursor: default;
}

.hand .unselected-cards .card {
  margin-top: 30px;
}

.hand .unselected-cards .card:hover svg {
  transform: translateY(-20px);
}

.hand .unselected-cards .card:hover .card-label {
  transform: translateY(-20px);
}

.card .card-icon {
  position: absolute;
  font-size: initial;
  left: 0.35em;
  z-index: 1;
}

.hand .unselected-cards .card:hover .card-icon {
  transform: translateY(-20px);
}

.kitty {
  margin-top: 20px;
  margin-bottom: 20px;
}

.always-show-labels .card .card-label,
.card:hover .card-label {
  display: inline-block;
}

.ReactModal__Overlay {
  z-index: 2;
}

.notify .card,
.card.notify {
  animation: blinkingText 1.5s 1;
  color: #000;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }
  50% {
    color: #aaa;
  }
  100% {
    color: #000;
  }
}

@keyframes blinkingTextDark {
  0% {
    color: #fff;
  }
  50% {
    color: #555;
  }
  100% {
    color: #fff;
  }
}

.dark-mode .notify .card,
.dark-mode .card.notify {
  animation: blinkingTextDark 1.5s 1;
  color: #fff;
}

.♢ {
  color: #bb0313;
}

.♡ {
  color: #bb0313;
}

.♤ {
  color: #000;
}

.♧ {
  color: #000;
}

.🃟 {
  color: #000;
}

.🃏 {
  color: #bb0313;
}

.points .card {
  cursor: default;
}

.four-color .♢,
.four-color.♢ {
  color: #1933f9;
}

.four-color .♡,
.four-color.♡ {
  color: #bb0313;
}

.four-color .♤,
.four-color.♤ {
  color: #000;
}

.four-color .♧,
.four-color.♧ {
  color: #477e1b;
}

.four-color .🃟,
.four-color.🃟 {
  color: #000;
}

.four-color .🃏,
.four-color.🃏 {
  color: #bb0313;
}

.dark-mode .♧,
.dark-mode.♧ {
  color: #fff;
}

.dark-mode .♤,
.dark-mode.♤,
.dark-mode .four-color .♤,
.dark-mode .four-color.♤ {
  color: #fff;
}

.dark-mode .🃟,
.dark-mode.🃟,
.dark-mode .four-color .🃟,
.dark-mode .four-color.🃟,
.darkmode.four-color.🃟 {
  color: #fff;
}

.hand p {
  margin: 0;
  text-align: center;
}

.hand .unselected-cards {
  padding: 0 10px;
}

.hand .selected-cards {
  padding: 0 10px;
}

.next {
  color: #1933f9;
  font-weight: bold;
}

.dark-mode .next {
  color: #96a2fa;
}

.landlord {
  background: #ddd;
}

.landlord .card svg {
  background: #ddd;
}

.dark-mode .landlord,
.dark-mode .landlord .card svg {
  background: #333;
}

.observer {
  border: 1px #222 dashed;
  color: #222;
}

.chat {
  min-width: 225px;
  width: 20%;
  border: 1px #eee solid;
  float: right;
  margin-top: 20px;
}

.dark-mode .chat {
  border: #222;
}

.game {
  min-width: 690px;
  min-height: 660px;
  padding: 0 20px;
  width: 70%;
  display: inline-block;
}

.hide-chat-box .game {
  width: 95%;
}

.messages {
  padding-top: 10px;
  padding-left: 10px;
  height: 400px;
  overflow-y: auto;
  overflow-anchor: none;
}

.chat-anchor {
  overflow-anchor: auto;
  height: 1px;
}

.message {
  margin: 0;
}

.game-message {
  color: #00f;
}

.labeled-play {
  display: inline-block;
  padding: 10px;
  border: 1px solid #000;
  margin-bottom: 20px;
}

.dark-mode .labeled-play {
  border-color: #fff;
}

.labeled-play .label {
  text-align: center;
  line-height: 1em;
}

.labeled-play .card-group {
  display: inline-block;
}

.labeled-play .card {
  cursor: default;
}

.labeled-play.clickable .card {
  cursor: pointer;
}

.labeled-play.winning {
  box-shadow: inset 0px 0px 0px 2px #bb0313;
}

.always-show-labels .more .card.svg .card-label,
.more .card.svg:hover .card-label {
  bottom: 1.8em;
}

.labeled-play .play {
  display: inline-block;
  padding-bottom: 20px;
}

.labeled-play .play + .play {
  margin-left: 10px;
}

.trump {
  margin: 10px 0;
}

.pending-friends p {
  margin: 0;
}

.pending-friends {
  margin: 10px;
}

.reset-block {
  float: right;
  text-align: center;
  width: 200px;
}

.reset-block p {
  margin-block: 0;
}

.red {
  color: #bb0313;
}

.join-room div {
  padding: 5px;
}

.join-room span {
  cursor: pointer;
}

.join-room h2 input {
  font-size: 24px;
}

.kicker button {
  padding: 3px;
  margin: 3px;
}

label {
  line-height: 25px;
}

/* used by rules.html */
.rules .card {
  font-size: 8em;
  margin-right: -0.4em;
}
.rules .card:last-child {
  margin-right: 0;
}
